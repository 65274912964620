import React, { Suspense } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Protected from './views/pages/protectedRoutes/Protected'
import './scss/style.scss'







const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))


const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))


const App = () => {
    const loading = (
        <div className='pt-3 text-center'>
            <div className="spinner-border text-warning" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )

    return (
        <>

            <BrowserRouter>
                <Suspense fallback={loading}>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/404" element={<Page404 />} />
                        <Route path="/500" element={<Page500 />} />

                        <Route path="/" element={<Navigate to="/login" />} />

                        {/* <Route path="*" element={<Navigate to="/login" />} /> */}

                        <Route path="*" element={
                            <Protected>
                                <DefaultLayout />
                            </Protected>


                        } />
                    </Routes>
                </Suspense>
            </BrowserRouter>
        </>

    )
}


export default App
